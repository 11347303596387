<template>
  <lf-input
    :placeholder="placeholder"
    :name="name"
    :value="displayedText"
    :type="inputType"
    :componentStyle="componentStyle"
    :disabled="editDisabled"
    autocomplete="current-password"
  >
    <template v-slot:suffix>
      <icon-base
        @click="togglePassword"
        :icon="iconType"
        width="16"
        height="14"
        :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
        :icon-color="UTIL_COLORS.DEFAULT"
      />
    </template>
  </lf-input>
</template>
<script setup lang="ts">
import { OBFUSCATED_PREFIX, UTIL_COLORS } from "@/helpers/constants";
import { isObfuscated } from "@/helpers/deals";
import { ref, computed } from "vue";

const props = defineProps({
  value: {
    type: String
  },
  name: {
    type: String,
    default: "password"
  },
  placeholder: {
    type: String,
    default: "Password"
  },
  type: {
    type: String,
    default: "password"
  },
  componentStyle: {
    type: String
  },
  fetchHiddenData: {
    type: Function,
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const inputType = ref(props.type === "password" ? "password" : "text");
const showValue = ref(false);
const displayedText = computed(() =>
  props.type === "password" || (showValue.value && props.type === "piis")
    ? props.value
    : `${OBFUSCATED_PREFIX}${props.value?.slice(-2)}`
);
const iconType = computed(() => (!showValue.value ? "hide-pass" : "show-pass"));
const editDisabled = computed(() => {
  return props.disabled || isObfuscated(displayedText.value || "");
});

const togglePassword = async () => {
  if (props.disabled) {
    return;
  }
  if (props.type === "piis") {
    if (props.fetchHiddenData && isObfuscated(props.value || "")) {
      await props.fetchHiddenData();
    }
    showValue.value = !showValue.value;
    return;
  }

  if (inputType.value === "password") {
    inputType.value = "text";
    return;
  }
  inputType.value = "password";
  showValue.value = !showValue.value;
};
</script>
